var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Filter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 24px 0;\n  background-color: ", ";\n  color: #223554;\n  padding: 15px 18px;\n  box-shadow: 0 1px 4px #22365429;\n  position:relative;\n"], ["\n  margin: 24px 0;\n  background-color: ", ";\n  color: #223554;\n  padding: 15px 18px;\n  box-shadow: 0 1px 4px #22365429;\n  position:relative;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisPale;
});
export var FilterLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    padding-bottom : 2rem;\n    @media (max-width: 767px) {\n      padding-bottom: ", "; \n    }\n"], ["\n    display: flex;\n    padding-bottom : 2rem;\n    @media (max-width: 767px) {\n      padding-bottom: ", "; \n    }\n"])), function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? "2rem" : "0");
});
export var FilterHeader = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-weight: bold;\n    width: 100%;\n    cursor: pointer;\n"], ["\n    font-weight: bold;\n    width: 100%;\n    cursor: pointer;\n"])));
export var FilterClean = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display : flex;\n  justify-content: space-between;\n  font-size: 14px;\n  @media (max-width: 767px) {\n    flex-direction: column;\n    .open{\n      padding-top: 24px;\n    }\n  }\n"], ["\n  display : flex;\n  justify-content: space-between;\n  font-size: 14px;\n  @media (max-width: 767px) {\n    flex-direction: column;\n    .open{\n      padding-top: 24px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
