var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CleanFilterBtn = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nfont-size:14px;\ncolor: ", ";\nfont-weight:bold;\ncursor:pointer;\n"], ["\nfont-size:14px;\ncolor: ", ";\nfont-weight:bold;\ncursor:pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
var templateObject_1;
