var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MultiSelectioncontainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n margin-bottom:1rem;\n"], ["\n margin-bottom:1rem;\n"])));
export var LabelColor = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    margin-right:8px;\n    margin-top:3px;\n"], ["\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    margin-right:8px;\n    margin-top:3px;\n"])));
export var FilterTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size:14px;\n    color:", ";\n    font-weight:bold;\n"], ["\n    font-size:14px;\n    color:", ";\n    font-weight:bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var DropdownGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background-color: white;\n    padding: 10px;\n    border:1px solid #808A9D;\n    color:#647287;\n"], ["\n    background-color: white;\n    padding: 10px;\n    border:1px solid #808A9D;\n    color:#647287;\n"])));
export var DropdownGroupLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n    font-size: 1rem;\n"], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n    font-size: 1rem;\n"])));
export var DropdownGroupContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    flex-direction: column;\n    gap: 16px;\n"], ["\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    flex-direction: column;\n    gap: 16px;\n"])));
export var Label = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: -webkit-box;\n    display: flex;\n    gap: 8px;\n    font-weight: 400;\n    font-size: 1.1rem;\n    cursor: pointer;\n    margin-top:16px;\n    color:", ";\n    align-items: center;\n    \n    input {\n        width:16px;\n        height:16px;\n    }\n"], ["\n    display: -webkit-box;\n    display: flex;\n    gap: 8px;\n    font-weight: 400;\n    font-size: 1.1rem;\n    cursor: pointer;\n    margin-top:16px;\n    color:", ";\n    align-items: center;\n    \n    input {\n        width:16px;\n        height:16px;\n    }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
