var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Children } from "react";
import { useTranslation } from 'react-i18next';
import { Filter, FilterLabel, FilterHeader, FilterClean } from "./Filter-styles.js";
import styled from "styled-components";
var chevron_down_blue = "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-down-blue.svg");
var chevron_up_blue = "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-up-blue.svg");
var ArrowDownIcon = styled.img.attrs({
    src: chevron_down_blue,
    alt: 'arrow down',
    className: 'arrow-down'
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: auto; /* Espacement entre l'ic\u00F4ne et le texte */\n  position: absolute;\n  padding-right: 8px;\n  padding-bottom: 24px;\n  padding-top: 6px;\n  right: 0;\n  @media (min-width: 768px) {\n    display: none; /* Masquer dans la version de bureau */\n  }\n"], ["\n  margin-left: auto; /* Espacement entre l'ic\u00F4ne et le texte */\n  position: absolute;\n  padding-right: 8px;\n  padding-bottom: 24px;\n  padding-top: 6px;\n  right: 0;\n  @media (min-width: 768px) {\n    display: none; /* Masquer dans la version de bureau */\n  }\n"])));
var ArrowUpIcon = styled.img.attrs({
    src: chevron_up_blue,
    alt: 'arrow up',
    className: 'arrow-up'
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: auto; /* Espacement entre l'ic\u00F4ne et le texte */\n  position: absolute;\n  padding-right: 8px;\n  padding-bottom: 24px;\n  padding-top: 6px;\n  right: 0;\n  @media (min-width: 768px) {\n    display: none; /* Masquer dans la version de bureau */\n  }\n"], ["\n  margin-left: auto; /* Espacement entre l'ic\u00F4ne et le texte */\n  position: absolute;\n  padding-right: 8px;\n  padding-bottom: 24px;\n  padding-top: 6px;\n  right: 0;\n  @media (min-width: 768px) {\n    display: none; /* Masquer dans la version de bureau */\n  }\n"])));
var MobileIcon = function (_a) {
    var isOpen = _a.isOpen, onClick = _a.onClick;
    return isOpen ? _jsx(ArrowUpIcon, { onClick: onClick }) : _jsx(ArrowDownIcon, { onClick: onClick });
};
export var FilterCard = function (_a) {
    var children = _a.children;
    var cleanFiltersBtn = Children.toArray(children)[0];
    var multiSelections = Children.toArray(children)[1];
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var t = useTranslation(['news']).t;
    var toggleMobileIcon = function () {
        setIsOpen(!isOpen);
    };
    var isMobile = window.innerWidth < 768;
    return (_jsx(React.Fragment, { children: _jsxs(Filter, { children: [_jsx(FilterLabel, __assign({ isOpen: isOpen }, { children: _jsx(FilterHeader, { children: _jsxs(FilterClean, { children: [t("Filtres"), isMobile && _jsx(MobileIcon, { isOpen: isOpen, onClick: toggleMobileIcon }), _jsx("div", __assign({ className: "clean-filters-btn ".concat(isOpen ? 'open' : '') }, { children: isOpen || !isMobile ? cleanFiltersBtn : null }))] }) }) })), isOpen || !isMobile ? multiSelections : null] }) }));
};
var templateObject_1, templateObject_2;
